import { FormKitNode } from '@formkit/core';
import {
  close,
  down,
  fileDoc,
  check,
  circle,
  spinner,
  star,
  trash,
  add,
  arrowUp,
  arrowDown,
  applicationIcons,
  genesisIcons,
} from '@formkit/icons';
import { createProPlugin, inputs } from '@formkit/pro';
import '@formkit/pro/genesis';
import { generateClasses } from '@formkit/themes';
import '@formkit/themes/genesis';
import { DefaultConfigOptions } from '@formkit/vue';
import theme from './formkit.theme';
import { en, es } from '@formkit/i18n';

const pro = createProPlugin('fk-644908e468', inputs);

const isCheckboxAndRadioMultiple = (node: FormKitNode) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;

function addOptionalLabelPlugin(node: FormKitNode) {
  node.on('created', () => {
    if (!node.props.definition) return;
    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');

      const { t } = useI18n();
      if (!isRequired) {
        if (isCheckboxAndRadioMultiple(node)) {
          sectionsSchema.legend = {
            attrs: {
              innerHTML: `${node.props.label} <span class="text-[20px] font-light text-gray-400">— ${t('Optional')}</span>`,
            },
          };
        } else {
          sectionsSchema.label = {
            attrs: {
              innerHTML: `${node.props.label} <span class="text-[20px] font-light text-gray-400">${
                node.name !== 'allowContact' ? '— ' + t('Optional') : ''
              }</span>`,
            },
          };
          if (node.props.id === 'pathwayofhope') {
            sectionsSchema.label.attrs.class = 'formkit-label';
          }
        }
      } else {
        const containsHtmlTags = (str: string) => {
          // This regex matches any string that contains <tag>...</tag> structures, including self-closing tags.
          const regex = /<[^>]*>/;
          return regex.test(str);
        };
        if (containsHtmlTags(node.props.label)) {
          sectionsSchema.label = {
            attrs: {
              innerHTML: node.props.label,
            },
          };

          if (node.props.id === 'pathwayofhope') {
            sectionsSchema.label.attrs.class = 'formkit-label';
          }
        }
      }
      return schemaFn(sectionsSchema);
    };
  });
  node.hook.submit((payload, next) => {
    node?.children?.forEach((child) => {
      if (child.context?.type === 'mask' && child.context?.mask === '{#,##|repeat}#.##') {
        if (child.context?.value.length === 1) {
          payload[child.name] = `${parseInt(child.context?.value) * 100}`;
        }
      }
    });
    return next(payload);
  });
}

const config: DefaultConfigOptions = {
  messages: {
    en: {
      validation: {
        required() {
          return `This field is required.`;
        },
        min({ name, args }) {
          return `Must be at least ${args[0]}.`;
        },
        length({ name, args, node }) {
          if (node.context?.type === 'mask' && node.context?.mask === '{#,##|repeat}#.##') {
            return `Please enter a minimum of 2 digits for confirmation.  For example, 0 should be entered as 00.`;
          } else {
            if (args.length > 1) {
              return `Limited to ${parseInt(args[1]).toLocaleString('en-US')} characters.`;
            } else {
              return `${name} must be greater than or equal to ${args[0]} characters.`;
            }
          }
        },
      },
    },
  },
  config: {
    classes: generateClasses(theme),
  },
  locales: { en, es },
  plugins: [pro, addOptionalLabelPlugin],
  icons: {
    ...applicationIcons,
    ...genesisIcons,
    close,
    down,
    fileDoc,
    check,
    circle,
    spinner,
    star,
    trash,
    add,
    arrowUp,
    arrowDown,
    checkboxDecorator: check,
  },
};

export default config;
