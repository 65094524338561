import { default as detailsrA0YqXnhFUMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/details.vue?macro=true";
import { default as indexV42NTTu3HZMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/index.vue?macro=true";
import { default as list3hQ8kOW9s8Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/appointment/list.vue?macro=true";
import { default as email_45change_45erroregTD06NqcMMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/email-change-error.vue?macro=true";
import { default as detailsFEsPO1hbTyMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/details.vue?macro=true";
import { default as distribution_45detailF9b6cTF1WyMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution-detail.vue?macro=true";
import { default as distributionPQJOtyuW23Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution.vue?macro=true";
import { default as indexy0AEa1X9lPMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/index.vue?macro=true";
import { default as matchnVYvz39ym8Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/match.vue?macro=true";
import { default as searchWq7B0wENBFMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/search.vue?macro=true";
import { default as submit_45distributionFeXwVKFaDdMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/submit-distribution.vue?macro=true";
import { default as generate_45errorzqZXanb9NtMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/generate-error.vue?macro=true";
import { default as applicantUTYmUR2H25Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/applicant.vue?macro=true";
import { default as email_45verification41HBW6f4s5Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/email-verification.vue?macro=true";
import { default as welcomec3xwyQLMscMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/home/welcome.vue?macro=true";
import { default as indexAZf4ZD2k7YMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/index.vue?macro=true";
import { default as authUasescsaJiMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/auth.vue?macro=true";
import { default as verificationnCUvr9zGzNMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/verification.vue?macro=true";
import { default as indexeCVguqtw5mMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/playground/index.vue?macro=true";
import { default as welcomeBr6rCAEoqXMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/preview/welcome.vue?macro=true";
import { default as privacy_45policyBnudBip3CkMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/privacy-policy.vue?macro=true";
import { default as applicant_45requestItjz5benstMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/applicant-request.vue?macro=true";
import { default as application_45submittedTv9cuHCxPjMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/application-submitted.vue?macro=true";
import { default as _91arid_93_45chat_45authpWuPQlOyToMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat-auth.vue?macro=true";
import { default as _91arid_93_45chat6tDkxYwBpsMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat.vue?macro=true";
import { default as _91step_93EoYGA1G9nZMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[step].vue?macro=true";
import { default as indexepryZImWbeMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/index.vue?macro=true";
import { default as assistance_45types0kBPlxWMUuMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-types.vue?macro=true";
import { default as client_45consent4tlsjrhuR4Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/client-consent.vue?macro=true";
import { default as document_45uploadGWfwmnAuZ0Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/document-upload.vue?macro=true";
import { default as email_45change_45verificationZfWCwCVyzqMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change-verification.vue?macro=true";
import { default as email_45changeI4WVFqlsi1Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change.vue?macro=true";
import { default as household_45incomeBor6BSWxnBMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/household-income.vue?macro=true";
import { default as household_45membersTZftaRUDpSMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/household-members.vue?macro=true";
import { default as meeting3qYV0TtCUEMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/meeting.vue?macro=true";
import { default as notice_45of_45privacy_45practices5UchwmpNywMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/notice-of-privacy-practices.vue?macro=true";
import { default as payee_45informationOrDuBGAjPmMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/payee-information.vue?macro=true";
import { default as personal_45details_45delete_45meOMjTy5xuWeMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-details-delete-me.vue?macro=true";
import { default as personal_45informationJUZvVuYlUOMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-information.vue?macro=true";
import { default as indexCVVNoWoSyxMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/index.vue?macro=true";
import { default as question_45_91index_93yl3532wXl0Meta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/question-[index].vue?macro=true";
import { default as welcometTBESa7RzYMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/welcome.vue?macro=true";
import { default as who_45applyingvYdBcpUchdMeta } from "/home/vsts/work/1/s/packages/sa-help/pages/secure/who-applying.vue?macro=true";
export default [
  {
    name: detailsrA0YqXnhFUMeta?.name ?? "appointment-id-details",
    path: detailsrA0YqXnhFUMeta?.path ?? "/appointment/:id()/details",
    meta: detailsrA0YqXnhFUMeta || {},
    alias: detailsrA0YqXnhFUMeta?.alias || [],
    redirect: detailsrA0YqXnhFUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: indexV42NTTu3HZMeta?.name ?? "appointment-id",
    path: indexV42NTTu3HZMeta?.path ?? "/appointment/:id()",
    meta: indexV42NTTu3HZMeta || {},
    alias: indexV42NTTu3HZMeta?.alias || [],
    redirect: indexV42NTTu3HZMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: list3hQ8kOW9s8Meta?.name ?? "appointment-list",
    path: list3hQ8kOW9s8Meta?.path ?? "/appointment/list",
    meta: list3hQ8kOW9s8Meta || {},
    alias: list3hQ8kOW9s8Meta?.alias || [],
    redirect: list3hQ8kOW9s8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/appointment/list.vue").then(m => m.default || m)
  },
  {
    name: email_45change_45erroregTD06NqcMMeta?.name ?? "email-change-error",
    path: email_45change_45erroregTD06NqcMMeta?.path ?? "/email-change-error",
    meta: email_45change_45erroregTD06NqcMMeta || {},
    alias: email_45change_45erroregTD06NqcMMeta?.alias || [],
    redirect: email_45change_45erroregTD06NqcMMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/email-change-error.vue").then(m => m.default || m)
  },
  {
    name: detailsFEsPO1hbTyMeta?.name ?? "fp-id-details",
    path: detailsFEsPO1hbTyMeta?.path ?? "/fp/:id()/details",
    meta: detailsFEsPO1hbTyMeta || {},
    alias: detailsFEsPO1hbTyMeta?.alias || [],
    redirect: detailsFEsPO1hbTyMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: distribution_45detailF9b6cTF1WyMeta?.name ?? "fp-id-distribution-detail",
    path: distribution_45detailF9b6cTF1WyMeta?.path ?? "/fp/:id()/distribution-detail",
    meta: distribution_45detailF9b6cTF1WyMeta || {},
    alias: distribution_45detailF9b6cTF1WyMeta?.alias || [],
    redirect: distribution_45detailF9b6cTF1WyMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution-detail.vue").then(m => m.default || m)
  },
  {
    name: distributionPQJOtyuW23Meta?.name ?? "fp-id-distribution",
    path: distributionPQJOtyuW23Meta?.path ?? "/fp/:id()/distribution",
    meta: distributionPQJOtyuW23Meta || {},
    alias: distributionPQJOtyuW23Meta?.alias || [],
    redirect: distributionPQJOtyuW23Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/distribution.vue").then(m => m.default || m)
  },
  {
    name: indexy0AEa1X9lPMeta?.name ?? "fp-id",
    path: indexy0AEa1X9lPMeta?.path ?? "/fp/:id()",
    meta: indexy0AEa1X9lPMeta || {},
    alias: indexy0AEa1X9lPMeta?.alias || [],
    redirect: indexy0AEa1X9lPMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: matchnVYvz39ym8Meta?.name ?? "fp-id-match",
    path: matchnVYvz39ym8Meta?.path ?? "/fp/:id()/match",
    meta: matchnVYvz39ym8Meta || {},
    alias: matchnVYvz39ym8Meta?.alias || [],
    redirect: matchnVYvz39ym8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/match.vue").then(m => m.default || m)
  },
  {
    name: searchWq7B0wENBFMeta?.name ?? "fp-id-search",
    path: searchWq7B0wENBFMeta?.path ?? "/fp/:id()/search",
    meta: searchWq7B0wENBFMeta || {},
    alias: searchWq7B0wENBFMeta?.alias || [],
    redirect: searchWq7B0wENBFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/search.vue").then(m => m.default || m)
  },
  {
    name: submit_45distributionFeXwVKFaDdMeta?.name ?? "fp-id-submit-distribution",
    path: submit_45distributionFeXwVKFaDdMeta?.path ?? "/fp/:id()/submit-distribution",
    meta: submit_45distributionFeXwVKFaDdMeta || {},
    alias: submit_45distributionFeXwVKFaDdMeta?.alias || [],
    redirect: submit_45distributionFeXwVKFaDdMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/fp/[id]/submit-distribution.vue").then(m => m.default || m)
  },
  {
    name: generate_45errorzqZXanb9NtMeta?.name ?? "generate-error",
    path: generate_45errorzqZXanb9NtMeta?.path ?? "/generate-error",
    meta: generate_45errorzqZXanb9NtMeta || {},
    alias: generate_45errorzqZXanb9NtMeta?.alias || [],
    redirect: generate_45errorzqZXanb9NtMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/generate-error.vue").then(m => m.default || m)
  },
  {
    name: applicantUTYmUR2H25Meta?.name ?? "home-applicant",
    path: applicantUTYmUR2H25Meta?.path ?? "/home/applicant",
    meta: applicantUTYmUR2H25Meta || {},
    alias: applicantUTYmUR2H25Meta?.alias || [],
    redirect: applicantUTYmUR2H25Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/applicant.vue").then(m => m.default || m)
  },
  {
    name: email_45verification41HBW6f4s5Meta?.name ?? "home-email-verification",
    path: email_45verification41HBW6f4s5Meta?.path ?? "/home/email-verification",
    meta: email_45verification41HBW6f4s5Meta || {},
    alias: email_45verification41HBW6f4s5Meta?.alias || [],
    redirect: email_45verification41HBW6f4s5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/email-verification.vue").then(m => m.default || m)
  },
  {
    name: welcomec3xwyQLMscMeta?.name ?? "home-welcome",
    path: welcomec3xwyQLMscMeta?.path ?? "/home/welcome",
    meta: welcomec3xwyQLMscMeta || {},
    alias: welcomec3xwyQLMscMeta?.alias || [],
    redirect: welcomec3xwyQLMscMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/home/welcome.vue").then(m => m.default || m)
  },
  {
    name: indexAZf4ZD2k7YMeta?.name ?? "index",
    path: indexAZf4ZD2k7YMeta?.path ?? "/",
    meta: indexAZf4ZD2k7YMeta || {},
    alias: indexAZf4ZD2k7YMeta?.alias || [],
    redirect: indexAZf4ZD2k7YMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/index.vue").then(m => m.default || m)
  },
  {
    name: authUasescsaJiMeta?.name ?? "payee-id-auth",
    path: authUasescsaJiMeta?.path ?? "/payee/:id()/auth",
    meta: authUasescsaJiMeta || {},
    alias: authUasescsaJiMeta?.alias || [],
    redirect: authUasescsaJiMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/auth.vue").then(m => m.default || m)
  },
  {
    name: verificationnCUvr9zGzNMeta?.name ?? "payee-id-verification",
    path: verificationnCUvr9zGzNMeta?.path ?? "/payee/:id()/verification",
    meta: verificationnCUvr9zGzNMeta || {},
    alias: verificationnCUvr9zGzNMeta?.alias || [],
    redirect: verificationnCUvr9zGzNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/payee/[id]/verification.vue").then(m => m.default || m)
  },
  {
    name: indexeCVguqtw5mMeta?.name ?? "playground",
    path: indexeCVguqtw5mMeta?.path ?? "/playground",
    meta: indexeCVguqtw5mMeta || {},
    alias: indexeCVguqtw5mMeta?.alias || [],
    redirect: indexeCVguqtw5mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/playground/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeBr6rCAEoqXMeta?.name ?? "preview-welcome",
    path: welcomeBr6rCAEoqXMeta?.path ?? "/preview/welcome",
    meta: welcomeBr6rCAEoqXMeta || {},
    alias: welcomeBr6rCAEoqXMeta?.alias || [],
    redirect: welcomeBr6rCAEoqXMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/preview/welcome.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyBnudBip3CkMeta?.name ?? "privacy-policy",
    path: privacy_45policyBnudBip3CkMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyBnudBip3CkMeta || {},
    alias: privacy_45policyBnudBip3CkMeta?.alias || [],
    redirect: privacy_45policyBnudBip3CkMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: applicant_45requestItjz5benstMeta?.name ?? "secure-applicant-request",
    path: applicant_45requestItjz5benstMeta?.path ?? "/secure/applicant-request",
    meta: applicant_45requestItjz5benstMeta || {},
    alias: applicant_45requestItjz5benstMeta?.alias || [],
    redirect: applicant_45requestItjz5benstMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/applicant-request.vue").then(m => m.default || m)
  },
  {
    name: application_45submittedTv9cuHCxPjMeta?.name ?? "secure-application-submitted",
    path: application_45submittedTv9cuHCxPjMeta?.path ?? "/secure/application-submitted",
    meta: application_45submittedTv9cuHCxPjMeta || {},
    alias: application_45submittedTv9cuHCxPjMeta?.alias || [],
    redirect: application_45submittedTv9cuHCxPjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/application-submitted.vue").then(m => m.default || m)
  },
  {
    name: _91arid_93_45chat_45authpWuPQlOyToMeta?.name ?? "secure-assistance-request-arid-chat-auth",
    path: _91arid_93_45chat_45authpWuPQlOyToMeta?.path ?? "/secure/assistance-request/:arid()-chat-auth",
    meta: _91arid_93_45chat_45authpWuPQlOyToMeta || {},
    alias: _91arid_93_45chat_45authpWuPQlOyToMeta?.alias || [],
    redirect: _91arid_93_45chat_45authpWuPQlOyToMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat-auth.vue").then(m => m.default || m)
  },
  {
    name: _91arid_93_45chat6tDkxYwBpsMeta?.name ?? "secure-assistance-request-arid-chat",
    path: _91arid_93_45chat6tDkxYwBpsMeta?.path ?? "/secure/assistance-request/:arid()-chat",
    meta: _91arid_93_45chat6tDkxYwBpsMeta || {},
    alias: _91arid_93_45chat6tDkxYwBpsMeta?.alias || [],
    redirect: _91arid_93_45chat6tDkxYwBpsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[arid]-chat.vue").then(m => m.default || m)
  },
  {
    name: _91step_93EoYGA1G9nZMeta?.name ?? "secure-assistance-request-step",
    path: _91step_93EoYGA1G9nZMeta?.path ?? "/secure/assistance-request/:step()",
    meta: _91step_93EoYGA1G9nZMeta || {},
    alias: _91step_93EoYGA1G9nZMeta?.alias || [],
    redirect: _91step_93EoYGA1G9nZMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/[step].vue").then(m => m.default || m)
  },
  {
    name: indexepryZImWbeMeta?.name ?? "secure-assistance-request",
    path: indexepryZImWbeMeta?.path ?? "/secure/assistance-request",
    meta: indexepryZImWbeMeta || {},
    alias: indexepryZImWbeMeta?.alias || [],
    redirect: indexepryZImWbeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-request/index.vue").then(m => m.default || m)
  },
  {
    name: assistance_45types0kBPlxWMUuMeta?.name ?? "secure-assistance-types",
    path: assistance_45types0kBPlxWMUuMeta?.path ?? "/secure/assistance-types",
    meta: assistance_45types0kBPlxWMUuMeta || {},
    alias: assistance_45types0kBPlxWMUuMeta?.alias || [],
    redirect: assistance_45types0kBPlxWMUuMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/assistance-types.vue").then(m => m.default || m)
  },
  {
    name: client_45consent4tlsjrhuR4Meta?.name ?? "secure-client-consent",
    path: client_45consent4tlsjrhuR4Meta?.path ?? "/secure/client-consent",
    meta: client_45consent4tlsjrhuR4Meta || {},
    alias: client_45consent4tlsjrhuR4Meta?.alias || [],
    redirect: client_45consent4tlsjrhuR4Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/client-consent.vue").then(m => m.default || m)
  },
  {
    name: document_45uploadGWfwmnAuZ0Meta?.name ?? "secure-document-upload",
    path: document_45uploadGWfwmnAuZ0Meta?.path ?? "/secure/document-upload",
    meta: document_45uploadGWfwmnAuZ0Meta || {},
    alias: document_45uploadGWfwmnAuZ0Meta?.alias || [],
    redirect: document_45uploadGWfwmnAuZ0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/document-upload.vue").then(m => m.default || m)
  },
  {
    name: email_45change_45verificationZfWCwCVyzqMeta?.name ?? "secure-email-change-verification",
    path: email_45change_45verificationZfWCwCVyzqMeta?.path ?? "/secure/email-change-verification",
    meta: email_45change_45verificationZfWCwCVyzqMeta || {},
    alias: email_45change_45verificationZfWCwCVyzqMeta?.alias || [],
    redirect: email_45change_45verificationZfWCwCVyzqMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change-verification.vue").then(m => m.default || m)
  },
  {
    name: email_45changeI4WVFqlsi1Meta?.name ?? "secure-email-change",
    path: email_45changeI4WVFqlsi1Meta?.path ?? "/secure/email-change",
    meta: email_45changeI4WVFqlsi1Meta || {},
    alias: email_45changeI4WVFqlsi1Meta?.alias || [],
    redirect: email_45changeI4WVFqlsi1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/email-change.vue").then(m => m.default || m)
  },
  {
    name: household_45incomeBor6BSWxnBMeta?.name ?? "secure-household-income",
    path: household_45incomeBor6BSWxnBMeta?.path ?? "/secure/household-income",
    meta: household_45incomeBor6BSWxnBMeta || {},
    alias: household_45incomeBor6BSWxnBMeta?.alias || [],
    redirect: household_45incomeBor6BSWxnBMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/household-income.vue").then(m => m.default || m)
  },
  {
    name: household_45membersTZftaRUDpSMeta?.name ?? "secure-household-members",
    path: household_45membersTZftaRUDpSMeta?.path ?? "/secure/household-members",
    meta: household_45membersTZftaRUDpSMeta || {},
    alias: household_45membersTZftaRUDpSMeta?.alias || [],
    redirect: household_45membersTZftaRUDpSMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/household-members.vue").then(m => m.default || m)
  },
  {
    name: meeting3qYV0TtCUEMeta?.name ?? "secure-meeting",
    path: meeting3qYV0TtCUEMeta?.path ?? "/secure/meeting",
    meta: meeting3qYV0TtCUEMeta || {},
    alias: meeting3qYV0TtCUEMeta?.alias || [],
    redirect: meeting3qYV0TtCUEMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/meeting.vue").then(m => m.default || m)
  },
  {
    name: notice_45of_45privacy_45practices5UchwmpNywMeta?.name ?? "secure-notice-of-privacy-practices",
    path: notice_45of_45privacy_45practices5UchwmpNywMeta?.path ?? "/secure/notice-of-privacy-practices",
    meta: notice_45of_45privacy_45practices5UchwmpNywMeta || {},
    alias: notice_45of_45privacy_45practices5UchwmpNywMeta?.alias || [],
    redirect: notice_45of_45privacy_45practices5UchwmpNywMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/notice-of-privacy-practices.vue").then(m => m.default || m)
  },
  {
    name: payee_45informationOrDuBGAjPmMeta?.name ?? "secure-payee-information",
    path: payee_45informationOrDuBGAjPmMeta?.path ?? "/secure/payee-information",
    meta: payee_45informationOrDuBGAjPmMeta || {},
    alias: payee_45informationOrDuBGAjPmMeta?.alias || [],
    redirect: payee_45informationOrDuBGAjPmMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/payee-information.vue").then(m => m.default || m)
  },
  {
    name: personal_45details_45delete_45meOMjTy5xuWeMeta?.name ?? "secure-personal-details-delete-me",
    path: personal_45details_45delete_45meOMjTy5xuWeMeta?.path ?? "/secure/personal-details-delete-me",
    meta: personal_45details_45delete_45meOMjTy5xuWeMeta || {},
    alias: personal_45details_45delete_45meOMjTy5xuWeMeta?.alias || [],
    redirect: personal_45details_45delete_45meOMjTy5xuWeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-details-delete-me.vue").then(m => m.default || m)
  },
  {
    name: personal_45informationJUZvVuYlUOMeta?.name ?? "secure-personal-information",
    path: personal_45informationJUZvVuYlUOMeta?.path ?? "/secure/personal-information",
    meta: personal_45informationJUZvVuYlUOMeta || {},
    alias: personal_45informationJUZvVuYlUOMeta?.alias || [],
    redirect: personal_45informationJUZvVuYlUOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/personal-information.vue").then(m => m.default || m)
  },
  {
    name: indexCVVNoWoSyxMeta?.name ?? "secure-service-areas",
    path: indexCVVNoWoSyxMeta?.path ?? "/secure/service-areas",
    meta: indexCVVNoWoSyxMeta || {},
    alias: indexCVVNoWoSyxMeta?.alias || [],
    redirect: indexCVVNoWoSyxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/index.vue").then(m => m.default || m)
  },
  {
    name: question_45_91index_93yl3532wXl0Meta?.name ?? "secure-service-areas-question-index",
    path: question_45_91index_93yl3532wXl0Meta?.path ?? "/secure/service-areas/question-:index()",
    meta: question_45_91index_93yl3532wXl0Meta || {},
    alias: question_45_91index_93yl3532wXl0Meta?.alias || [],
    redirect: question_45_91index_93yl3532wXl0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/question-[index].vue").then(m => m.default || m)
  },
  {
    name: welcometTBESa7RzYMeta?.name ?? "secure-service-areas-welcome",
    path: welcometTBESa7RzYMeta?.path ?? "/secure/service-areas/welcome",
    meta: welcometTBESa7RzYMeta || {},
    alias: welcometTBESa7RzYMeta?.alias || [],
    redirect: welcometTBESa7RzYMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/service-areas/welcome.vue").then(m => m.default || m)
  },
  {
    name: who_45applyingvYdBcpUchdMeta?.name ?? "secure-who-applying",
    path: who_45applyingvYdBcpUchdMeta?.path ?? "/secure/who-applying",
    meta: who_45applyingvYdBcpUchdMeta || {},
    alias: who_45applyingvYdBcpUchdMeta?.alias || [],
    redirect: who_45applyingvYdBcpUchdMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/packages/sa-help/pages/secure/who-applying.vue").then(m => m.default || m)
  }
]